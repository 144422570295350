import { create } from 'zustand';
import axios from 'axios';

// Create a Zustand store for property data
export const usePropertyStore = create((set, get) => ({
  // State
  topLocalities: [],
  sortOptions: [],
  cities: [],
  localities: [],
  loadingSortOptions: false,
  loadingCities: false,
  loadingLocalities: false,
  sortOptionsError: null,
  citiesError: null,
  localitiesError: null,
  loading: false,
  error: null,
  currentSortOption: 'Relevance',
  selectedCity: null,
  selectedLocalities: [], // Store selected localities
  selectedPriceRange: '₹ 100K - ₹ 250K', // Default price range
  selectedPropertyType: 'Apartment', // Default property type
  searchResults: [], // Store search results
  searchLoading: false, // Loading state for search
  searchError: null, // Error state for search
  
  // Actions
  fetchTopLocalities: async (sortOption) => {
    // Update current sort option if provided
    if (sortOption) {
      set({ currentSortOption: sortOption });
    }
    
    // Get current sort option from state
    const currentSort = sortOption || get().currentSortOption;
    
    // Map frontend sort options to backend sort parameters
    const sortMapping = {
      'Relevance': { sort_by: 'created_at', sort_order: 'DESC' },
      'Price: Low to High': { sort_by: 'monthly_rent', sort_order: 'ASC' },
      'Price: High to Low': { sort_by: 'monthly_rent', sort_order: 'DESC' },
      'Newest First': { sort_by: 'created_at', sort_order: 'DESC' },
      'Most Popular': { sort_by: 'views', sort_order: 'DESC' },
      'Highest Rated': { sort_by: 'rating', sort_order: 'DESC' }
    };
    
    // Get sort parameters based on selected option
    const { sort_by, sort_order } = sortMapping[currentSort] || sortMapping['Relevance'];
    try {
      set({ loading: true, error: null });
      // Include sort parameters in the request
      const response = await axios.get('http://localhost:3002/api/v1/properties/top-10-locallities', {
        params: { sort_by, sort_order }
      });
      
      // Map backend data to match the component's expected format
      const mappedData = response.data.data.properties.map(property => ({
        id: property.id, // Make sure to include property ID
        price: `₹${property.monthly_rent}`,
        verified: true,
        amenities: [
          {
            icon: 'BedIcon',
            title: `${property.property_type === 'Apartment' ? property.title.split('BHK')[0] || '3' : '3'} Beds`
          },
          property.bathrooms ? {
            icon: 'BathIcon',
            title: `${property.bathrooms} Baths`
          } : null,
          property.buildup_area ? {
            icon: 'SquareFitIcon',
            title: `${property.buildup_area} sq. ft`
          } : null
        ].filter(Boolean),
        location: property.address || property.area || property.city,
        img: property.PropertyImages && property.PropertyImages.length > 0 ? 
             property.PropertyImages[0].image_url : 
             "/images/container-1.png",
      }));
      
      set({ topLocalities: mappedData, loading: false });
    } catch (error) {
      console.error('Error fetching top localities:', error);
      set({ 
        error: 'Failed to fetch top localities',
        loading: false,
        // Fallback data if API fails
        topLocalities: get().topLocalities.length > 0 ? get().topLocalities : [
          {
            id: 1,
            price: "80K",
            verified: true,
            amenities: [
              {
                icon: 'BedIcon',
                title: "3 Beds",
              },
              {
                icon: 'BathIcon',
                title: "2 Baths",
              },
              {
                icon: 'SquareFitIcon',
                title: "2400 sq. ft",
              },
            ],
            location: "Eco La brea- Coliving.",
            img: "/images/Container.png",
          },
          {
            id: 2,
            price: "90K",
            verified: true,
            amenities: [
              {
                icon: 'BedIcon',
                title: "4 Beds",
              },
              {
                icon: 'BathIcon',
                title: "3 Baths",
              },
              {
                icon: 'SquareFitIcon',
                title: "2500 sq. ft",
              },
            ],
            location: "Bopal Approach",
            img: "/images/container-1.png",
          },
          {
            id: 3,
            price: "10K",
            verified: true,
            amenities: [
              {
                icon: 'BathIcon',
                title: "1 Bath",
              },
              {
                icon: 'SquareFitIcon',
                title: "1100 sq. ft",
              },
            ],
            location: "Vadaj",
            img: "/images/container-2.png",
          },
        ]
      });
    }
  },
  
  // New method for searching properties
  searchProperties: async () => {
    const state = get();
    const { selectedCity, selectedLocalities, selectedPriceRange, selectedPropertyType, currentSortOption } = state;
    
    // Validate required fields
    if (!selectedCity) {
      set({ searchError: 'Please select a city' });
      return;
    }
    
    // Parse price range values
    const priceRangeMap = {
      '₹ 50K - ₹ 100K': { min: 50000, max: 100000 },
      '₹ 100K - ₹ 250K': { min: 100000, max: 250000 },
      '₹ 250K - ₹ 500K': { min: 250000, max: 500000 },
      '₹ 500K+': { min: 500000, max: null }
    };
    
    const { min, max } = priceRangeMap[selectedPriceRange] || { min: 0, max: null };
    
    // Map frontend sort options to backend sort parameters
    const sortMapping = {
      'Relevance': { sort_by: 'created_at', sort_order: 'DESC' },
      'Price: Low to High': { sort_by: 'monthly_rent', sort_order: 'ASC' },
      'Price: High to Low': { sort_by: 'monthly_rent', sort_order: 'DESC' },
      'Newest First': { sort_by: 'created_at', sort_order: 'DESC' },
      'Most Popular': { sort_by: 'views', sort_order: 'DESC' },
      'Highest Rated': { sort_by: 'rating', sort_order: 'DESC' }
    };
    
    // Get sort parameters based on selected option
    const { sort_by, sort_order } = sortMapping[currentSortOption] || sortMapping['Relevance'];
    
    try {
      set({ searchLoading: true, searchError: null });
      
      // Build search parameters
      const params = {
        city: selectedCity,
        min_price: min,
        max_price: max,
        property_type: selectedPropertyType,
        sort_by,
        sort_order
      };
      
      // Add localities if selected
      if (selectedLocalities && selectedLocalities.length > 0) {
        params.area = selectedLocalities.join(',');
      }
      
      // Make API request
      const response = await axios.get('http://localhost:3002/api/v1/properties/search', { params });
      
      // Map backend data to match the component's expected format
      const mappedData = response.data.data.properties.map(property => ({
        id: property.id,
        price: `₹${property.monthly_rent}`,
        verified: true,
        amenities: [
          {
            icon: 'BedIcon',
            title: `${property.property_type === 'Apartment' ? property.title.split('BHK')[0] || '3' : '3'} Beds`
          },
          property.bathrooms ? {
            icon: 'BathIcon',
            title: `${property.bathrooms} Baths`
          } : null,
          property.buildup_area ? {
            icon: 'SquareFitIcon',
            title: `${property.buildup_area} sq. ft`
          } : null
        ].filter(Boolean),
        location: property.address || property.area || property.city,
        img: property.PropertyImages && property.PropertyImages.length > 0 ? 
             property.PropertyImages[0].image_url : 
             "/images/container-1.png",
      }));
      
      set({ searchResults: mappedData, searchLoading: false, topLocalities: mappedData });
    } catch (error) {
      console.error('Error searching properties:', error);
      set({ 
        searchError: 'Failed to search properties',
        searchLoading: false
      });
    }
  },
  
  // Fetch sort options from API
  fetchSortOptions: async () => {
    try {
      set({ loadingSortOptions: true, sortOptionsError: null });
      const response = await axios.get('http://localhost:3002/api/v1/properties/sort-options');
      
      set({ sortOptions: response.data.data.sortOptions || [], loadingSortOptions: false });
    } catch (error) {
      console.error('Error fetching sort options:', error);
      set({ 
        sortOptionsError: 'Failed to fetch sort options',
        loadingSortOptions: false,
        // Fallback data if API fails
        sortOptions: [
          { key: '1', label: 'Relevance' },
          { key: '2', label: 'Price: Low to High' },
          { key: '3', label: 'Price: High to Low' },
          { key: '4', label: 'Newest First' },
        ]
      });
    }
  },
  
  // Clear state when needed
  clearPropertyData: () => set({ 
    topLocalities: [], 
    sortOptions: [],
    cities: [],
    localities: [], 
    error: null, 
    sortOptionsError: null,
    citiesError: null,
    localitiesError: null,
    currentSortOption: 'Relevance',
    selectedCity: null,
    selectedLocalities: [],
    selectedPriceRange: '₹ 100K - ₹ 250K',
    selectedPropertyType: 'Apartment',
    searchResults: [],
    searchLoading: false,
    searchError: null
  }),
  
  // Set current sort option and refetch data
  setSortAndFetch: (sortOption) => {
    set({ currentSortOption: sortOption });
    // If search results exist, apply sort to search results instead of fetching top localities
    if (get().searchResults.length > 0) {
      get().searchProperties();
    } else {
      get().fetchTopLocalities(sortOption);
    }
  },
  
  // Fetch cities from the backend
  fetchCities: async () => {
    try {
      set({ loadingCities: true, citiesError: null });
      const response = await axios.get('http://localhost:3002/api/v1/properties/cities');
      
      // Handle the new response format where cities is an array of strings
      const formattedCities = response.data.data.cities.map(cityName => ({
        value: cityName,
        label: cityName,
        id: cityName // Using the city name as ID since no IDs are provided
      }));
      
      set({ 
        cities: formattedCities, 
        loadingCities: false,
        selectedCity: formattedCities.length > 0 ? formattedCities[0].value : null
      });
      
      // If we have a city selected, fetch its localities
      if (formattedCities.length > 0) {
        get().fetchLocalities(formattedCities[0].value); // Use city name instead of ID
      }
      
    } catch (error) {
      console.error('Error fetching cities:', error);
      set({ 
        citiesError: 'Failed to fetch cities',
        loadingCities: false,
        // Fallback data if API fails
        cities: [
          { value: 'Ahmedabad, Gujarat', label: 'Ahmedabad, Gujarat', id: 1 },
          { value: 'Mumbai, Maharashtra', label: 'Mumbai, Maharashtra', id: 2 },
          { value: 'Delhi, Delhi', label: 'Delhi, Delhi', id: 3 },
          { value: 'Bangalore, Karnataka', label: 'Bangalore, Karnataka', id: 4 },
        ]
      });
    }
  },
  
  // Fetch localities for a specific city
  fetchLocalities: async (cityName) => {
    try {
      set({ loadingLocalities: true, localitiesError: null });
      const response = await axios.get(`http://localhost:3002/api/v1/properties/location/localities`, {
        params: { city: cityName } // Using city name instead of ID
      });
      
      // Assuming localities follow the same pattern as cities (array of strings)
      const formattedLocalities = response.data.data.localities.map(localityName => ({
        value: localityName,
        label: localityName,
        id: localityName // Using locality name as ID since no IDs are provided
      }));
      
      set({ localities: formattedLocalities, loadingLocalities: false });
    } catch (error) {
      console.error('Error fetching localities:', error);
      set({ 
        localitiesError: 'Failed to fetch localities',
        loadingLocalities: false,
        // Fallback data if API fails
        localities: [
          { value: 'South Bopal, Sola +2 more', label: 'South Bopal, Sola +2 more', id: 1 },
          { value: 'Navrangpura, Vastrapur', label: 'Navrangpura, Vastrapur', id: 2 },
          { value: 'Satellite, Bodakdev', label: 'Satellite, Bodakdev', id: 3 },
          { value: 'Maninagar, Vastral', label: 'Maninagar, Vastral', id: 4 },
        ]
      });
    }
  },
  
  // Set selected city and fetch its localities
  setSelectedCity: (cityData) => {
    set({ selectedCity: cityData.value });
    get().fetchLocalities(cityData.value); // Use city name instead of ID
  },
  
  // Set selected localities
  setSelectedLocalities: (localities) => {
    set({ selectedLocalities: localities });
  },
  
  // Set selected price range
  setSelectedPriceRange: (priceRange) => {
    set({ selectedPriceRange: priceRange });
  },
  
  // Set selected property type
  setSelectedPropertyType: (propertyType) => {
    set({ selectedPropertyType: propertyType });
  }
}));