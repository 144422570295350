import React, { useEffect, useState } from "react";
import Background from "../../src/Assets/Images/MainBGGreen.png";
import Tree from "../../src/Assets/Images/MainBGGreen.png";
import Villa from "../../src/Assets/Images/HouseGreen.png";
import Overlay from "../../src/Assets/Images/LeafsGreen.png";
import HeroSection from "./HeroSection";

const Hero = () => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const handleMouseMove = (e) => {
            const x = (e.clientX / window.innerWidth - 0.5) * 1;
            const y = (e.clientY / window.innerHeight - 0.5) * 1;
            setMousePosition({ x, y });
        };

        window.addEventListener("mousemove", handleMouseMove);
        setTimeout(() => setIsLoaded(true), 100);
        return () => window.removeEventListener("mousemove", handleMouseMove);
    }, []);

    return (
        <div>
            <HeroSection />
        </div>
    );
};

export default Hero;
