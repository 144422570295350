import "./App.css";
import Home from "./Components/Home";
import BlogHome from "./Pages/Blog/home.tsx";
import BlogDetail from "./Pages/Blog/detail.tsx";

import FAQ from "./Pages/faq.tsx";
import NoPageFound from "./Pages/404.tsx";
import About from "./Pages/about-us.tsx";
import FAQDetail from "./Pages/faq-detail.tsx";
import PropertyDetail from "./Pages/property/property-detail.tsx";

import "./styles/fonts.css";
import "./styles/main.scss";
import "./styles/color-schema.scss";

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  console.log("app")
  return (
    <div className="text-center guest-home-site-wrapper">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="blog" element={<BlogHome />} />
          <Route path="blog/:blogTitle" element={<BlogDetail />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="faq/:faqTitle" element={<FAQDetail />} />
          <Route path="about-us" element={<About />} />
          <Route path="property/:detail" element={<PropertyDetail />} />
          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
