import React, { useState, useEffect } from "react";
import {
  BathIcon,
  BedIcon,
  DollorIcon,
  FilterIcon,
  HouseIcon,
  LocationIcon,
  PinIcon,
  SearchIcon,
  SortByIcon,
  SquareFitIcon,
  StarPinIcon,
  VerifiedIcon,
} from "../Assets/Icons.tsx";
import { Select, Button, Switch, Dropdown, message } from "antd";
import { Link } from "react-router-dom";
import "./antd-overrides.css";
import { usePropertyStore } from "../store/propertyStore.js";

const HeroSection = () => {
  const [mapView, setMapView] = useState(false);
  const [verifiedOnly, setVerifiedOnly] = useState(true);
  const [searchResultsCount, setSearchResultsCount] = useState(200);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [sortOption, setSortOption] = useState("Relevance");
  const [localSelectedLocalities, setLocalSelectedLocalities] = useState([]);
  const [localSelectedPriceRange, setLocalSelectedPriceRange] =
    useState("₹ 100K - ₹ 250K");
  const [localSelectedPropertyType, setLocalSelectedPropertyType] =
    useState("Apartment");

  const {
    topLocalities,
    loading,
    error,
    fetchTopLocalities,
    sortOptions,
    loadingSortOptions,
    sortOptionsError,
    fetchSortOptions,
    setSortAndFetch,
    currentSortOption,
    cities,
    localities,
    loadingCities,
    loadingLocalities,
    citiesError,
    localitiesError,
    fetchCities,
    fetchLocalities,
    setSelectedCity,
    selectedCity,
    // New properties and methods
    searchProperties,
    searchLoading,
    searchError,
    searchResults,
    setSelectedLocalities,
    setSelectedPriceRange,
    setSelectedPropertyType,
  } = usePropertyStore();

  const handleMapViewToggle = (checked) => {
    setMapView(checked);
  };

  const handleVerifiedOnlyToggle = (checked) => {
    setVerifiedOnly(checked);
  };

  useEffect(() => {
    let count = 200;
    if (verifiedOnly) {
      count = Math.floor(count * 0.7);
    }
    if (searchResults && searchResults.length > 0) {
      count = searchResults.length;
    }
    setSearchResultsCount(count);
  }, [verifiedOnly, selectedFilters, searchResults]);

  useEffect(() => {
    fetchTopLocalities();
    fetchSortOptions();
    fetchCities(); // Fetch cities when component mounts
  }, [fetchTopLocalities, fetchSortOptions, fetchCities]);

  // Handle the search button click
  const handleSearch = () => {
    if (!selectedCity) {
      message.error("Please select a city");
      return;
    }

    // Update the global state with local state values
    setSelectedLocalities(localSelectedLocalities);
    setSelectedPriceRange(localSelectedPriceRange);
    setSelectedPropertyType(localSelectedPropertyType);

    // Execute the search
    searchProperties();
  };

  const rentalHomes = [
    {
      price: "80K",
      verified: true,
      amenities: [
        {
          icon: <BedIcon />,
          title: "Beds",
        },
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "2400 sq. ft",
        },
      ],
      location: "Eco La brea- Coliving.",
      img: "/images/Container.png",
    },
    {
      price: "90K",
      verified: true,
      amenities: [
        {
          icon: <BedIcon />,
          title: "Beds",
        },
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "2500 sq. ft",
        },
      ],
      location: "Bopal Approach",
      img: "/images/container-1.png",
    },
    {
      price: "10K",
      verified: true,
      amenities: [
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "1100 sq. ft",
        },
      ],
      location: "Vadaj",
      img: "/images/container-2.png",
    },
    {
      price: "10K",
      verified: true,
      amenities: [
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "1100 sq. ft",
        },
      ],
      location: "Vadaj",
      img: "/images/container-2.png",
    },
    {
      price: "80K",
      verified: true,
      amenities: [
        {
          icon: <BedIcon />,
          title: "Beds",
        },
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "2400 sq. ft",
        },
      ],
      location: "Eco La brea- Coliving.",
      img: "/images/Container.png",
    },
    {
      price: "90K",
      verified: true,
      amenities: [
        {
          icon: <BedIcon />,
          title: "Beds",
        },
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "2500 sq. ft",
        },
      ],
      location: "Bopal Approach",
      img: "/images/container-1.png",
    },
    {
      price: "10K",
      verified: true,
      amenities: [
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "1100 sq. ft",
        },
      ],
      location: "Vadaj",
      img: "/images/container-2.png",
    },
    {
      price: "10K",
      verified: true,
      amenities: [
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "1100 sq. ft",
        },
      ],
      location: "Vadaj",
      img: "/images/container-2.png",
    },
  ];

  return (
    <>
      <div className="guesthom-aletrnate-hero-section">
        <div className="top-area">
          <h1>
            From Renting to Owning,
            <br /> We Help You Discover Your Perfect Home
          </h1>
        </div>
        <div className="container">
          <div className="property-info">
            <div className="d-flex">
              <div className="info-block">
                <div className="info-row">
                  <div className="icon">
                    <PinIcon />
                  </div>
                  <div className="icon-title">City</div>
                </div>
                <Select
                  value={selectedCity}
                  style={{ width: "100%" }}
                  bordered={false}
                  className="description"
                  options={
                    loadingCities
                      ? [{ value: "loading", label: "Loading cities..." }]
                      : citiesError
                      ? [{ value: "error", label: "Error loading cities" }]
                      : cities.length > 0
                      ? cities
                      : [
                          {
                            value: "Ahmedabad, Gujarat",
                            label: "Ahmedabad, Gujarat",
                          },
                        ]
                  }
                  showSearch={true}
                  placeholder="Select city"
                  optionFilterProp="label"
                  onChange={(value, option) => setSelectedCity(option)}
                  loading={loadingCities}
                />
              </div>
              <div className="info-block">
                <div className="info-row">
                  <div className="icon">
                    <StarPinIcon />
                  </div>
                  <div className="icon-title">Locality</div>
                </div>
                <Select
                  style={{ width: "100%" }}
                  bordered={false}
                  className="description"
                  options={
                    loadingLocalities
                      ? [{ value: "loading", label: "Loading localities..." }]
                      : localitiesError
                      ? [{ value: "error", label: "Error loading localities" }]
                      : localities.length > 0
                      ? localities
                      : [
                          {
                            value: "South Bopal, Sola +2 more",
                            label: "South Bopal, Sola +2 more",
                          },
                          {
                            value: "Navrangpura, Vastrapur",
                            label: "Navrangpura, Vastrapur",
                          },
                          {
                            value: "Satellite, Bodakdev",
                            label: "Satellite, Bodakdev",
                          },
                          {
                            value: "Maninagar, Vastral",
                            label: "Maninagar, Vastral",
                          },
                        ]
                  }
                  mode="multiple"
                  maxTagCount={1}
                  maxTagPlaceholder={(omittedValues) =>
                    `+ ${omittedValues.length} ...`
                  }
                  showSearch={true}
                  placeholder="Select localities"
                  optionFilterProp="label"
                  loading={loadingLocalities}
                  onChange={(values) => setLocalSelectedLocalities(values)}
                />
              </div>
              <div className="info-block">
                <div className="info-row">
                  <div className="icon">
                    <DollorIcon />
                  </div>
                  <div className="icon-title">Price Range</div>
                </div>
                <Select
                  defaultValue="₹ 100K - ₹ 250K"
                  style={{ width: "100%" }}
                  bordered={false}
                  className="description"
                  options={[
                    { value: "₹ 50K - ₹ 100K", label: "₹ 50K - ₹ 100K" },
                    { value: "₹ 100K - ₹ 250K", label: "₹ 100K - ₹ 250K" },
                    { value: "₹ 250K - ₹ 500K", label: "₹ 250K - ₹ 500K" },
                    { value: "₹ 500K+", label: "₹ 500K+" },
                  ]}
                  placeholder="Select price range"
                  onChange={(value) => setLocalSelectedPriceRange(value)}
                />
              </div>
              <div className="info-block">
                <div className="info-row">
                  <div className="icon">
                    <HouseIcon />
                  </div>
                  <div className="icon-title">Property Type</div>
                </div>
                <Select
                  defaultValue="Apartment"
                  style={{ width: "100%" }}
                  bordered={false}
                  className="description"
                  options={[
                    { value: "Apartment", label: "Apartment" },
                    { value: "Villa", label: "Villa" },
                    { value: "Independent House", label: "Independent House" },
                    { value: "Plot", label: "Plot" },
                  ]}
                  placeholder="Select property type"
                  onChange={(value) => setLocalSelectedPropertyType(value)}
                />
              </div>
              <div className="search-block">
                <Button
                  type="primary"
                  className="search-button"
                  icon={<SearchIcon />}
                  onClick={handleSearch}
                  loading={searchLoading}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="popular-localities">
            <span>Popular Localities:</span>
            <div className="d-flex">
              {loadingLocalities ? (
                <div className="badge">Loading localities...</div>
              ) : localitiesError ? (
                <div className="badge">Error loading localities</div>
              ) : localities.length > 0 ? (
                localities.slice(0, 4).map((locality, index) => (
                  <div className="badge" key={index}>
                    {locality.label}
                  </div>
                ))
              ) : (
                <>
                  <div className="badge">Vastrapur</div>
                  <div className="badge">Prahladnagar</div>
                  <div className="badge">Bodakdev</div>
                  <div className="badge">Sindhu Bhavan Road</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className={`nearby-similar-homes ${mapView ? "map-view-active" : ""}`}
        >
          <div className="container">
            <div className="search-results-area">
              <h6>
                {searchError ? (
                  <span className="error-text">{searchError}</span>
                ) : (
                  `Showing ${searchResultsCount}+ search results`
                )}
              </h6>
              <div className="actions-area">
                <div className="toggle-wrapper">
                  <span className="toggle-label">Map View</span>
                  <Switch
                    checked={mapView}
                    onChange={handleMapViewToggle}
                    className="map-view-toggle"
                  />
                </div>
                <div className="toggle-wrapper verified-switch">
                  <span className="toggle-label">Verified Only</span>
                  <Switch
                    checked={verifiedOnly}
                    onChange={handleVerifiedOnlyToggle}
                    className="verified-only-toggle"
                  />
                </div>
                <div className="action-btns">
                  <button className="btn btn-primary">
                    <span className="btn-icon">
                      <FilterIcon />
                    </span>
                    <span className="btn-text">Filter</span>
                  </button>
                  <Dropdown
                    menu={{
                      items: loadingSortOptions
                        ? [{ key: "loading", label: "Loading..." }]
                        : sortOptionsError
                        ? [{ key: "error", label: "Error loading options" }]
                        : sortOptions.length > 0
                        ? sortOptions
                        : [
                            { key: "1", label: "Relevance" },
                            { key: "2", label: "Price: Low to High" },
                            { key: "3", label: "Price: High to Low" },
                            { key: "4", label: "Newest First" },
                          ],
                      onClick: (e) => {
                        // Skip if we're showing loading or error state
                        if (e.key === "loading" || e.key === "error") return;

                        // Find the selected option and set it
                        const selectedOption = sortOptions.find(
                          (option) => option.key === e.key
                        );
                        let newSortOption;

                        if (selectedOption) {
                          newSortOption = selectedOption.label;
                        } else {
                          // Fallback for hardcoded options
                          newSortOption =
                            e.key === "1"
                              ? "Relevance"
                              : e.key === "2"
                              ? "Price: Low to High"
                              : e.key === "3"
                              ? "Price: High to Low"
                              : "Newest First";
                        }

                        // Update local state
                        setSortOption(newSortOption);

                        // Update global state and fetch sorted data
                        setSortAndFetch(newSortOption);
                      },
                    }}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <button className="btn btn-primary">
                      <span className="btn-icon">
                        <SortByIcon />
                      </span>
                      <span className="btn-text">Sort By: {sortOption}</span>
                    </button>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            {/* Map View Content */}
            <div
              className="map-view-content"
              style={{ display: mapView ? "block" : "none" }}
            >
              <div
                className="map-container"
                style={{
                  height: "500px",
                  background: "#f0f2f5",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3>Map View Content</h3>
                {/* Map component would be integrated here */}
              </div>
            </div>

            {/* List View Content */}
            <div
              className="list-view-content cards-area"
              style={{ display: mapView ? "none" : "block" }}
            >
              {loading || searchLoading ? (
                <div className="loading-state">Loading properties...</div>
              ) : error || searchError ? (
                <div className="error-state">{error || searchError}</div>
              ) : rentalHomes.length === 0 ? (
                <div className="no-results">
                  No properties found matching your criteria.
                </div>
              ) : (
                <div className="d-flex home-row">
                  {rentalHomes.map((home, index) => {
                    return (
                      <div className="col-3 col-md-3 col-sm-12" key={index}>
                        <Link
                          to={`/property/${home.id || index}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <div className="card">
                            <div
                              className="card-body"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="image-block">
                                <img src={home.img} alt={home.location} />
                              </div>
                              <div className="right-block">
                                <div className="d-flex home-card-row">
                                  <h5 className="card-title">
                                    {home.price} / <span>mo</span>
                                  </h5>
                                  {home.verified && (
                                    <span className="sub-text">
                                      <VerifiedIcon />
                                    </span>
                                  )}
                                </div>

                                <div className="amenitites">
                                  {home.amenities.length > 0 && (
                                    <span
                                      className="amenity"
                                      key={home.amenities[0].title}
                                    >
                                      <span className="icon">
                                        {home.amenities[0].icon ===
                                        "BedIcon" ? (
                                          <BedIcon />
                                        ) : home.amenities[0].icon ===
                                          "BathIcon" ? (
                                          <BathIcon />
                                        ) : home.amenities[0].icon ===
                                          "SquareFitIcon" ? (
                                          <SquareFitIcon />
                                        ) : null}
                                      </span>
                                      {home.amenities[0].title &&
                                      home.amenities[0].title.length > 12
                                        ? `${home.amenities[0].title.substring(
                                            0,
                                            12
                                          )}...`
                                        : home.amenities[0].title}
                                    </span>
                                  )}
                                  {home.amenities.length > 1 && (
                                    <span
                                      className="amenity"
                                      style={{ fontSize: "12px" }}
                                    >
                                      +{home.amenities.length - 1} more
                                    </span>
                                  )}
                                </div>
                                <p className="card-text">
                                  <span className="icon">
                                    <LocationIcon color="var(--color-steel)" />
                                  </span>
                                  {home.location && home.location.length > 20
                                    ? `${home.location.substring(0, 20)}...`
                                    : home.location}
                                </p>
                                <span className="green-text">
                                  Available Now • 240m away
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
