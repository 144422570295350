import React from "react";
import { useState } from "react";
import "./style.scss";

const ScheduleTour = () => {
  const [selectedDate, setSelectedDate] = useState("Thu 26");
  const [selectedTime, setSelectedTime] = useState("02:00 PM");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });

  const dates = ["Mon 23", "Tue 24", "Wed 25", "Thu 26", "Fri 27"];
  const times = [
    "10:30 AM",
    "11:30 AM",
    "12:00 AM",
    "01:00 PM",
    "02:00 PM",
    "03:00 AM",
    "04:00 AM",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="w-96 p-6 rounded-lg tour-wrapper">
      <h2 className="font-semibold">Schedule A Tour</h2>
      <select className="w-full p-2 mt-2 border rounded-lg">
        <option>December 2024</option>
      </select>
      <div className="flex justify-between mt-4 date-selection-wrapper">
        {dates.map((date) => (
          <button
            key={date}
            className={`p-2 rounded-lg ${
              selectedDate === date
                ? "bg-blue-800 text-white active-btn"
                : "bg-gray-100"
            }`}
            onClick={() => setSelectedDate(date)}
          >
            {date}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-3 gap-2 mt-4 time-selection-wrapper">
        {times.map((time) => (
          <button
            key={time}
            className={`p-2 rounded-lg ${
              selectedTime === time
                ? "bg-blue-800 text-white active-btn"
                : "bg-gray-100"
            }`}
            onClick={() => setSelectedTime(time)}
          >
            {time}
          </button>
        ))}
      </div>
      <div className="mt-4 schedule-form-wrapper">
        <input
          type="text"
          name="fullName"
          placeholder="Enter your name"
          className="w-full p-2 mt-2 border rounded-lg"
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          placeholder="name@email.com"
          className="w-full p-2 mt-2 border rounded-lg"
          onChange={handleChange}
        />
        <input
          type="tel"
          name="phone"
          placeholder="+91 XXXXX XXXXX"
          className="w-full p-2 mt-2 border rounded-lg"
          onChange={handleChange}
        />
        <textarea
          name="message"
          placeholder="Enter your message here"
          className="w-full p-2 mt-2 border rounded-lg"
          onChange={handleChange}
        ></textarea>
      </div>
      <button className="w-full p-2 mt-4 text-white bg-blue-800 rounded-lg button-submit">
        Schedule Tour
      </button>
    </div>
  );
};

export default ScheduleTour;
