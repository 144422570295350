import React, { useEffect, useState, useRef } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "./mapStyle.scss";

const locations = [
  {
    id: 1,
    position: [23.0225, 72.5714],
    title: "South Bopal",
    buyPrice: "7,213",
    rentRange: "40k- 4L",
    propertiesCount: 287,
    rentPropertiesCount: 1278,
  },
  {
    id: 2,
    position: [23.0425, 72.5814],
    title: "Gota",
    buyPrice: "7,213",
    rentRange: "40k- 4L",
    propertiesCount: 287,
    rentPropertiesCount: 1278,
  },
  {
    id: 3,
    position: [23.0325, 72.5914],
    title: "S.P Ring Road",
    buyPrice: "7,213",
    rentRange: "40k- 4L",
    propertiesCount: 287,
    rentPropertiesCount: 1278,
  },
];

const PropertyMap = () => {
  const mapRef = useRef(null);
  const markersRef = useRef([]);
  const [map, setMap] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  // Add debounce function to prevent frequent updates
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    const mapInstance = L.map("map", {
      center: [23.0325, 72.5814],
      zoom: 13,
      zoomControl: false,
      attributionControl: false,
    });

    L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
      {
        maxZoom: 19,
        attribution: "",
      }
    ).addTo(mapInstance);

    L.control
      .zoom({
        position: "topright",
      })
      .addTo(mapInstance);

    setMap(mapInstance);
    mapRef.current = mapInstance;

    return () => {
      mapInstance.remove();
    };
  }, []);

  // Create markers effect
  useEffect(() => {
    if (!map) return;

    // Clear existing markers
    if (markersRef.current.length > 0) {
      markersRef.current.forEach((marker) => {
        if (marker && marker.remove) {
          marker.remove();
        }
      });
      markersRef.current = [];
    }

    // Update tooltip position with debouncing
    const updateTooltipPosition = debounce((position) => {
      const point = map.latLngToLayerPoint(position);
      setTooltipPosition({ top: point.y, left: point.x });
    }, 50); // 50ms debounce delay

    // Create new markers
    const newMarkers = locations.map((location) => {
      const createMarkerIcon = (isActive = false) => {
        return L.divIcon({
          className: `location-marker ${isActive ? "active" : ""}`,
          html: `
                        <div class="ripple-container ${
                          isActive ? "active" : ""
                        }">
                            <div class="ripple"></div>
                            <div class="ripple"></div>
                            <div class="ripple"></div>
                            <div class="marker-dot"></div>
                        </div>
                    `,
          iconSize: [200, 200],
          iconAnchor: [100, 100],
        });
      };

      const marker = L.marker(location.position, {
        icon: createMarkerIcon(false),
      })
        .addTo(map)
        .on("mouseover", () => {
          setActiveMarker(location.id);
          updateTooltipPosition(location.position);
          marker.setIcon(createMarkerIcon(true));
        })
        .on("mouseout", (e) => {
          // Check if mouse is still within map container
          const mapContainer = document.querySelector(".map-container");
          const rect = mapContainer.getBoundingClientRect();

          if (
            e.originalEvent.clientX < rect.left ||
            e.originalEvent.clientX > rect.right ||
            e.originalEvent.clientY < rect.top ||
            e.originalEvent.clientY > rect.bottom
          ) {
            setActiveMarker(null);
          } else {
            // Only reset this specific marker if mouse moved outside it
            marker.setIcon(createMarkerIcon(false));

            // Small delay to check if it moved to another marker
            setTimeout(() => {
              // If no new active marker was set during this time, clear it
              const currentActive = markersRef.current.find((m) =>
                m.options.icon.options.className.includes("active")
              );

              if (!currentActive) {
                setActiveMarker(null);
              }
            }, 50);
          }
        });

      return marker;
    });

    markersRef.current = newMarkers;

    // Fit map to show all markers
    const bounds = L.latLngBounds(locations.map((loc) => loc.position));
    map.fitBounds(bounds, { padding: [50, 50] });

    // Update tooltip positions when map moves
    const handleMapMove = () => {
      if (activeMarker) {
        const activeLocation = locations.find((loc) => loc.id === activeMarker);
        if (activeLocation) {
          updateTooltipPosition(activeLocation.position);
        }
      }
    };

    map.on("move", handleMapMove);

    return () => {
      map.off("move", handleMapMove);
    };
  }, [map]);

  // Handle active marker changes
  useEffect(() => {
    if (!map) return;

    // If there's no active marker, ensure any tooltip is hidden
    if (!activeMarker) {
      return;
    }

    const activeLocation = locations.find((loc) => loc.id === activeMarker);
    if (activeLocation) {
      const point = map.latLngToLayerPoint(activeLocation.position);
      setTooltipPosition({ top: point.y, left: point.x });
    }
  }, [activeMarker, map]);

  // Add global mouseout handler for the map container
  useEffect(() => {
    if (!map) return;

    const mapContainer = document.querySelector(".map-container");

    const handleMouseLeave = () => {
      setActiveMarker(null);
    };

    if (mapContainer) {
      mapContainer.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (mapContainer) {
        mapContainer.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [map]);

  return (
    <div className="map-container">
      <div className="container"></div>
      <div id="map" style={{ height: "100%", width: "100%" }} />

      {activeMarker !== null && (
        <div
          className="property-card"
          style={{
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
            position: "absolute",
            transform: "translate(-50%, -100%)", // Center the tooltip above the marker
            pointerEvents: "none", // Prevent the tooltip from interfering with mouse events
            transition: "all 0.1s ease-out", // Smooth transition for tooltip movement
            zIndex: 1000, // Ensure it's above other elements
            display: activeMarker === null ? "none" : "block",
          }}
        >
          {locations
            .filter((location) => location.id === activeMarker)
            .map((location) => (
              <React.Fragment key={location.id}>
                <h6>{location.title}</h6>
                <div className="d-flex map-propery-row first-child">
                  <div className="property-info">
                    <div className="property-type">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      </svg>
                      Buy
                    </div>
                    <div className="property-value">
                      {location.rentPropertiesCount.toLocaleString()}
                    </div>
                  </div>
                  <div className="property-info">
                    <div className="property-type">Properties Available</div>
                    <div className="property-value">
                      {location.propertiesCount}
                    </div>
                  </div>
                </div>
                <div className="d-flex map-propery-row">
                  <div className="property-info">
                    <div className="property-type">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                      </svg>
                      Rent
                    </div>
                    <div className="property-value">₹{location.rentRange}</div>
                  </div>
                  <div className="property-info">
                    <div className="property-type">Properties Available</div>
                    <div className="property-value">
                      {location.rentPropertiesCount}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
        </div>
      )}

      <button className="view-all-button">View All Properties Near You</button>
    </div>
  );
};

export default PropertyMap;
