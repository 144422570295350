import React, { useState } from "react";
import Header from "../../Components/Header";
import "../../styles/property.scss";
import { Link } from "react-router-dom";
import {
  BathIcon,
  BedIcon,
  BedroomIcon,
  BlogBackIcon,
  LocationIcon,
  SquareFitIcon,
  VerifiedIcon,
} from "../../Assets/Icons.tsx";
import SubHeading from "../../Components/sub-heading.tsx";
import PopularSearch from "../../Components/popular-search.tsx";
import Footer from "../../Components/footer.tsx";
import MortgageCalculator from "../../Components/calculator/calculator.tsx";
import PriceTrend from "../../Components/price-trend-graph.tsx";
import AvgPriceSqft from "../../Components/avg-price-graph.tsx";
import PopularLocations from "../../Components/popular-location-graph.tsx";
import ScheduleTour from "../../Components/product-tour/product-tour.tsx";

const PropertyDetail = () => {
  const [selectedFloor, setSelectedFloor] = useState("Ground Floor");
  const floorPlans = {
    "Ground Floor": [
      "/images/propert-detail/floor-plan-img-1.png",
      "/images/propert-detail/floor-plan-img-2.png",
    ],
    "First Floor": [
      "/images/propert-detail/floor-plan-img-2.png",
      "/images/propert-detail/floor-plan-img-2.png",
    ],
    "Third Floor": [
      "/images/propert-detail/floor-plan-img-1.png",
      "/images/propert-detail/floor-plan-img-1.png",
    ],
  };
  const rentalHomes = [
    {
      price: "80K",
      verified: true,
      amenities: [
        {
          icon: <BedIcon />,
          title: "Beds",
        },
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "2400 sq. ft",
        },
      ],
      location: "Eco La brea- Coliving.",
      img: "/images/container-1.png",
    },
    {
      price: "90K",
      verified: true,
      amenities: [
        {
          icon: <BedIcon />,
          title: "Beds",
        },
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "2500 sq. ft",
        },
      ],
      location: "Bopal Approach",
      img: "/images/container-1.png",
    },
    {
      price: "10K",
      verified: true,
      amenities: [
        {
          icon: <BathIcon />,
          title: "Baths",
        },
        {
          icon: <SquareFitIcon />,
          title: "1100 sq. ft",
        },
      ],
      location: "Vadaj",
      img: "/images/container-2.png",
    },
  ];
  return (
    <>
      <div className="guesthom-property-details-wrapper">
        <Header />
        <section className="property-detail-hero-section">
          <div className="container">
            <div className="title-area">
              <div className="read-detail">
                <span>
                  <Link to="/blog">
                    <BlogBackIcon />
                  </Link>
                </span>
                <span className="breadcrumbs">
                  Ahmedabad Houses / S.G Highway / Residential Flats
                </span>
              </div>
              <h2>Aashray Elegance Overview</h2>
            </div>
          </div>
        </section>
        <section className="overview-image-section">
          <div className="container">
            <div className="overview-img-block">
              <img
                src="/images/propert-detail/property-overview.png"
                alt="property-overview-img"
              />
            </div>
          </div>
        </section>
        <section className="property-detail-section">
          <div className="container">
            <div className="d-flex property-detail-section-row">
              <div className="left-column">
                <div className="property-overview-wrapper">
                  <div className="details">
                    <div className="d-flex details-row">
                      <div>
                        <h4>₹80,00,000</h4>
                        <div className="d-flex text-row">
                          <span>2 months ago </span>
                          <span>|</span>
                          <span> 200m away from your location</span>
                        </div>
                      </div>
                      <div>
                        <span className="d-flex sub-text">
                          <VerifiedIcon />
                          Verified
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="overview">
                    <SubHeading
                      heading="Overview"
                      color="var(--color-pilot-blue)"
                    ></SubHeading>
                    <div className="amenities-overview">
                      <div className="feature-block">
                        <div className="feature-icon">
                          <BedroomIcon />
                        </div>
                        <div className="feature">Bedroom</div>
                        <div className="count">2</div>
                      </div>
                      <div className="feature-block">
                        <div className="feature-icon">
                          <BedroomIcon />
                        </div>
                        <div className="feature">Bath</div>
                        <div className="count">2</div>
                      </div>
                      <div className="feature-block">
                        <div className="feature-icon">
                          <BedroomIcon />
                        </div>
                        <div className="feature">Year Built</div>
                        <div className="count">2019</div>
                      </div>
                      <div className="feature-block">
                        <div className="feature-icon">
                          <BedroomIcon />
                        </div>
                        <div className="feature">Parking</div>
                        <div className="count">2</div>
                      </div>
                      <div className="feature-block">
                        <div className="feature-icon">
                          <BedroomIcon />
                        </div>
                        <div className="feature">Property Type</div>
                        <div className="count">Bunglow</div>
                      </div>
                    </div>
                  </div>
                  <div className="property-description-overview">
                    <SubHeading
                      heading="Property description"
                      color="var(--color-pilot-blue)"
                    ></SubHeading>
                    <div className="description">
                      <p>
                        As a homegrown Ahmedabad brand, we are committed to
                        addressing the needs of both the local and expat
                        communities. With a deep understanding of the regional
                        property market, we’ve developed tailored solutions to
                        meet the unique requirements of property seekers and
                        agents. Guided by our core values, every decision we
                        make is aimed at elevating the market and driving
                        regional growth while making property journeys seamless
                        and rewarding for everyone involved.
                      </p>
                    </div>
                  </div>
                  <div className="property-information">
                    <SubHeading
                      heading="Property Information"
                      color="var(--color-pilot-blue)"
                    ></SubHeading>
                    <div className="info-table-wrapper">
                      <div className="d-flex">
                        <div className="left-col">
                          <div className="key-value-pair">
                            <div className="key-block">Type</div>
                            <div className="value-block">Bunglow</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Purpose</div>
                            <div className="value-block">For Sale</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Reference no.</div>
                            <div className="value-block">AH-SIL-BG-07</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Completion</div>
                            <div className="value-block">Off-Plan</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Facing</div>
                            <div className="value-block">East</div>
                          </div>
                        </div>
                        <div className="right-col">
                          <div className="key-value-pair">
                            <div className="key-block">Balcony</div>
                            <div className="value-block">Connected</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Furnishing</div>
                            <div className="value-block">Fully-Furnished</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Verified Check™</div>
                            <div className="value-block">30 December 2024</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Added On</div>
                            <div className="value-block">30 December 2024</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Handover Date</div>
                            <div className="value-block">Q4 2023</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="property-information">
                    <SubHeading
                      heading="Validated Information"
                      color="var(--color-pilot-blue)"
                    ></SubHeading>
                    <div className="info-table-wrapper">
                      <div className="d-flex">
                        <div className="left-col">
                          <div className="key-value-pair">
                            <div className="key-block">Developer</div>
                            <div className="value-block">Shivalik</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Ownership</div>
                            <div className="value-block">Freehold</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Plot Area</div>
                            <div className="value-block">3,200 sqft</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Built-Up Area</div>
                            <div className="value-block">3,100 sqft</div>
                          </div>
                        </div>
                        <div className="right-col">
                          <div className="key-value-pair">
                            <div className="key-block">Power Phase</div>
                            <div className="value-block">
                              Double Phase, 3kW 50Hz
                            </div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Furnishing</div>
                            <div className="value-block">Fully-Furnished</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Power Backup</div>
                            <div className="value-block">Available</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Usage</div>
                            <div className="value-block">Home</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="floor-plans">
                    <SubHeading
                      heading="Floor Plans"
                      color="var(--color-pilot-blue)"
                    ></SubHeading>
                    <div className="floor-plan-data">
                      <div className="d-flex btns-row">
                        {Object.keys(floorPlans).map((floor) => (
                          <button
                            key={floor}
                            onClick={() => setSelectedFloor(floor)}
                            className={selectedFloor === floor ? "active" : ""}
                          >
                            {floor}
                          </button>
                        ))}
                      </div>
                      <div className="d-flex plan-img-row">
                        {floorPlans[selectedFloor].map((image, index) => (
                          <div className="floor-img-block" key={index}>
                            <img
                              src={image}
                              alt={`floor-plan-${selectedFloor}-${index}`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="features-amanities">
                    <div className="d-flex">
                      <SubHeading
                        heading="Features & Amenities"
                        color="var(--color-pilot-blue)"
                      ></SubHeading>
                    </div>
                    <div className="amenities-wrapper">
                      <div className="features-row">
                        <div className="f-block">
                          <div className="icon">
                            <img
                              src="/images/propert-detail/ventilation.png"
                              alt="ventilation"
                            />
                          </div>
                          <div className="desc">
                            Designed for Light & Ventilation
                          </div>
                        </div>
                        <div className="f-block">
                          <div className="icon">
                            <img
                              src="/images/propert-detail/power-bkup.png"
                              alt="power-bkup"
                            />
                          </div>
                          <div className="desc">24/7 Power Backup</div>
                        </div>
                        <div className="f-block">
                          <div className="icon">
                            <img
                              src="/images/propert-detail/kids-play.png"
                              alt="kids-play"
                            />
                          </div>
                          <div className="desc">Kids Play Area</div>
                        </div>
                        <div className="f-block">
                          <div className="icon">
                            <img
                              src="/images/propert-detail/amphitheater.png"
                              alt="amphitheater"
                            />
                          </div>
                          <div className="desc">Amphitheater</div>
                        </div>
                        <div className="f-block">
                          <div className="icon">
                            <img
                              src="/images/propert-detail/modern-fire-safety.png"
                              alt="modern-fire-safety"
                            />
                          </div>
                          <div className="desc">Modern Fire Safety</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="property-information">
                    <SubHeading
                      heading="Project Information"
                      color="var(--color-pilot-blue)"
                    ></SubHeading>
                    <div className="info-table-wrapper">
                      <div className="d-flex">
                        <div className="left-col">
                          <div className="key-value-pair">
                            <div className="key-block">Project Name</div>
                            <div className="value-block">
                              The Fields by Shivalik
                            </div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Ownership</div>
                            <div className="value-block">Freehold</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Project Status</div>
                            <div className="value-block">Finished</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Last Inspected</div>
                            <div className="value-block">2019</div>
                          </div>
                        </div>
                        <div className="right-col">
                          <div className="key-value-pair">
                            <div className="key-block">Developer</div>
                            <div className="value-block">Shivalik Group</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">Completion</div>
                            <div className="value-block">100%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="graph-area">
                    <SubHeading
                      heading="Trends"
                      color="var(--color-pilot-blue)"
                    ></SubHeading>
                    <div className="graph-block">
                      <div className="price-trend">
                        <div className="graph-info-block">
                          <div>Price Trend</div>
                          <div className="small-text">
                            View the listing price trends of similar properties
                          </div>
                        </div>
                        <PriceTrend />
                      </div>

                      <div
                        className="row-graph"
                        style={{ display: "flex", gap: "20px" }}
                      >
                        <div className="avg-price-graph">
                          <div className="graph-info-block">
                            <div>Average price/sqft</div>
                            <div className="small-text">
                              for other 2 bed Bungalows in The Fields
                            </div>
                          </div>
                          <AvgPriceSqft />
                        </div>
                        <div className="popular-locations">
                          <div className="graph-info-block">
                            <div>Popular locations</div>
                            <div className="small-text">
                              with 2 bed Bungalows in The Fields
                            </div>
                          </div>
                          <PopularLocations />
                        </div>
                      </div>
                      <ul>
                        <li>
                          These trends are calculated using a proprietary
                          algorithm based on prices advertised on GuestHom.
                        </li>
                        <li>
                          Popularity is based on searches conducted by users on
                          GuestHom over the last quarter.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="calculator-block">
                    <SubHeading
                      heading="Mortgage Calculator"
                      color="var(--color-pilot-blue)"
                    ></SubHeading>
                    <MortgageCalculator />
                  </div>
                  <div className="property-information">
                    <SubHeading
                      heading="Regulatory Information"
                      color="var(--color-pilot-blue)"
                    ></SubHeading>
                    <div className="info-table-wrapper">
                      <div className="d-flex">
                        <div className="left-col">
                          <div className="key-value-pair">
                            <div className="key-block">Permit Number</div>
                            <div className="value-block">69228719912</div>
                          </div>
                          <div className="key-value-pair">
                            <div className="key-block">RERA</div>
                            <div className="value-block">20088192</div>
                          </div>
                        </div>
                        <div className="right-col">
                          <div className="key-value-pair">
                            <div className="key-block">BRN</div>
                            <div className="value-block">6877281</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nearby-similar-homes">
                    <SubHeading
                      heading="Nearby Similar Homes"
                      color="var(--color-pilot-blue)"
                    ></SubHeading>
                    <div className="cards-area">
                      <div className="d-flex home-row">
                        {rentalHomes.map((home, index) => {
                          return (
                            <div
                              className="col-6 col-md-6 col-sm-12"
                              key={index}
                            >
                              <Link to="/property/top-11-locallities" className="btn btn-green">
                              <div className="card">
                                <div className="card-body">
                                  <div className="image-block">
                                    <img src={home.img} alt={home.location} />
                                  </div>
                                  <div className="right-block">
                                    <div className="d-flex home-card-row">
                                      <h5 className="card-title">
                                        ₹ {home.price} / <span>mo</span>
                                      </h5>
                                      {home.verified && (
                                        <span className="sub-text">
                                          <VerifiedIcon />
                                          Verified
                                        </span>
                                      )}
                                    </div>

                                    <div className="amenitites">
                                      {home.amenities.map(
                                        (amenity, subIndex) => {
                                          return (
                                            <span
                                              className="amenity"
                                              key={amenity.title}
                                            >
                                              <span className="icon">
                                                {amenity.icon}
                                              </span>
                                              {amenity.title}
                                            </span>
                                          );
                                        }
                                      )}
                                    </div>
                                    <p className="card-text">
                                      {" "}
                                      <span className="icon">
                                        <LocationIcon color="var(--color-steel)" />
                                      </span>
                                      {home.location}
                                    </p>
                                    <span className="green-text">
                                      Available Now • 240m away
                                    </span>
                                  </div>
                                </div>
                              </div>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-column">
                <div className="schedule-tour-container">
                  <ScheduleTour />
                </div>
              </div>
            </div>
          </div>
        </section>
        <PopularSearch />
        <Footer />
      </div>
    </>
  );
};
export default PropertyDetail;
